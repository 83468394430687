@each $color, $value in $theme-colors {
  .modal-#{$color} {
    @include modal-variant($value);
  }
}

.modal-colored {
  .modal-header,
  .modal-footer {
    border-color: rgba(255, 255, 255, 0.33);
  }
}

.modal {
  .modal-header {
    padding: 2rem;

    .modal-title {
      font-size: 1.2rem;
      font-weight: bold;
    }

    &.no-line {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .modal-footer {
    padding: 2rem;
  }
}
