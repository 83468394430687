.SingleDatePickerInput_calendarIcon {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  padding: 0.5rem;
  margin-right: 0.3rem;
}

.SingleDatePickerInput_calendarIcon:focus {
  outline: none;
}

.DateInput_input,
.DateInput_input__focused {
  height: calc(2.2rem + 2px);
  padding: 0.1rem 1rem 0 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.45rem;
  border: 1px solid #ced4da;
}

.DateInput_input__focused {
  border-color: #9ec2f3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(44, 123, 229, 0.25);
}

.SingleDatePickerInput__showClearDate {
  padding-right: 0;
}

.SingleDatePickerInput_clearDate__small {
  right: 2rem;
  padding: 0.3rem 0.4rem;
}

.is-invalid .DateInput_input {
  border-color: #e51c23;
}
.is-invalid .DateInput_input__focused {
  border-color: #e51c23;
  box-shadow: 0 0 0 0.2rem rgba(229, 28, 35, 0.25);
}

.is-valid .DateInput_input {
  border-color: #4caf50;
}
.is-valid .DateInput_input__focused {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}

.CalendarDay__default:hover {
  background: #deebff;
}

.DateInput_input__readOnly {
  cursor: pointer;
}

.SingleDatePickerInput__disabled,
.DateInput__disabled {
  background: transparent;
  color: #495057;
  cursor: default;
}

.SingleDatePickerInput__disabled .SingleDatePickerInput_calendarIcon {
  cursor: default;
}

.SingleDatePickerInput__disabled .SingleDatePickerInput_clearDate__small {
  cursor: default;
  opacity: 0;
}

.DateInput_input__disabled {
  background-color: #e9ecef;
  color: #495057;
  font-style: normal;
  cursor: default;
}

.DateInput_input__disabled::placeholder {
  color: #495057;
  opacity: 0.8;
}

.CalendarDay__selected_span {
  color: white;
}

.CalendarDay__selected {
  background: #2783ff;
  color: white;
}

.CalendarDay__selected:hover {
  background: #2783ff;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #8fccff;
  color: #ffffff;
  border: 1px double #6aadff;
  outline: none;
}

.DateRangePickerInput_arrow {
  margin: 0 0.5rem;
}

.DateRangePicker_picker {
  z-index: 10;
}

.CalendarDay__default:active {
  outline: none;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  outline: none;
  border: 1px double #2783ff;
  background: #2783ff;
  color: white;
}

.CalendarDay__selected_span {
  background: #a4deff;
  border: 1px double #6cbbff;
}

.CalendarDay__default:hover {
  border: 1px double #2783ff;
  background: #2783ff;
  color: #fff;
}

.SingleDatePicker_picker {
  z-index: 9;
}
