.form-control-no-border {
  border: 0;
  border-radius: 0;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    outline: 0;
  }
}

.is-invalid ~ .invalid-feedback {
  display: block;
}

.invalid-feedback {
  display: block;
}

.custom-checkbox {
  .custom-control-label {
    cursor: pointer;
  }
}

.table-striped tbody tr.tr-invalid {
  background-color: #ffbbbb;
}

.react-select-container {
  > .react-select__control {
    box-sizing: border-box;
    border-radius: 0.45rem;
    border: 1px solid #ced4da;
    cursor: pointer;
    height: calc(2.2rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    min-height: 36px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &.react-select__control--is-focused {
      color: #495057;
      background-color: #fff;
      border-color: #9ec2f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(44, 123, 229, 0.25);
    }

    &:hover {
      border-color: #ced4da;
    }

    .react-select__value-container {
      padding: 0 1rem;
    }

    .react-select__single-value {
      color: #495057;
    }

    .react-select__placeholder {
      color: #6b757d;
    }
  }

  > .react-select__menu {
    z-index: 3;
    .react-select__option {
      cursor: pointer;
    }
  }

  &.is-invalid {
    > .react-select__control {
      border-color: #e51c23;

      &.react-select__control--is-focused {
        border-color: #e51c23;
        box-shadow: 0 0 0 0.2rem rgba(229, 28, 35, 0.25);
      }
    }

    ~ .invalid-feedback {
      display: block;
    }
  }

  &.is-valid {
    > .react-select__control {
      border-color: #4caf50;

      &.react-select__control--is-focused {
        border-color: #4caf50;
        box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
      }
    }
  }
}
