.content {
  padding: $content-padding;

  @include media-breakpoint-down(md) {
    width: 100vw;
    max-width: 100vw;
  }

  @include media-breakpoint-down(lg) {
    padding: $spacer * 1.5;
  }
}
