.main {
  width: 100%;
  min-height: 100vh;
  min-width: 0;
  transition: $sidebar-transition;

  @include media-breakpoint-down(md) {
    overflow-y: hidden;
  }
}
