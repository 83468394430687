body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}

//Fix scss bootstrap
textarea.form-control {
  display: inline-block;
}
.form-control.is-valid,
.form-control.is-invalid {
  background-position: calc(100% - 0.6rem) center !important;
}
.form-control:disabled,
.btn:disabled {
  cursor: default;
}
.content {
  padding-bottom: 2.5rem !important;
}
// Calendar buttons fix
@media screen and (max-width: 532px) {
  .fc-toolbar {
    flex-flow: column;
  }
  .fc-center {
    order: 1;
  }
  .fc-left {
    margin: 10px 0;
    order: 2;
  }
  .fc-right {
    order: 3;
  }
}

// Action date iOS input fix
#actionDate {
  -webkit-appearance: none;
}
