.header {
  border-bottom: none;
  padding: 1.5rem 1.5rem 0 1.5rem !important;

  h5 {
    font-size: 1.2rem;
    font-weight: bold;
  }

  button:focus {
    outline: none;
  }
}

.body {
  font-size: 1rem;
  padding: 1.5rem !important;
}

.footer {
  border-top: none;
  padding: 0 1.5rem 1.5rem 1.5rem !important;
}
