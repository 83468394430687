.mainBlockContainer {
  min-width: 0;
}

.mainBlock {
  margin-bottom: -3.3rem;
  height: 100%;

  @media (max-width: 991.98px) {
    overflow-x: hidden;
  }
}
