input[type='text'],
input[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body.modal-open {
  right: 0;
  left: 0;
  bottom: 0;
}

.modal-open .modal {
  -webkit-overflow-scrolling: touch;
}

.modal-layout-wrapper {
  @media (min-width: 576px) and (max-width: 992px) {
    .modal-dialog.modal-lg {
      max-width: 98%;
      margin: 2% auto;
    }
  }
}
